<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedDeclarations"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'declaration-deces-add'}"
          class="btn btn-outline-primary btn-icon"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import moment from 'moment'
  import DataTable from '../../../components/dataTable/local.vue'
  import navbar from '../../../components/navbar.vue'
  import { TEXT_TYPE, COMPONENT_TYPE, DATE_TYPE} from '../../../components/dataTable/dataType'
  const Animal = () => import('../../../components/sanitaire/animal.vue')
  const CreateBy = () => import('../../../components/sanitaire/createdBy.vue')
  const Motif = () => import('../../../components/sanitaire/anamnese/motif.vue')
  const Actions = () => import('../../../components/identification/declarationDeces/declarationDecesAction.vue')
  export default {
      components: {navbar,  DataTable },
      data(){
          return {
            navbarItems: [
                  {
                      libelle: 'Identification'
                  },
                  {
                      libelle: 'Déclaration de décès',
                  }
                  ],
                  pageIcon: 'la-skull-crossbones',
                  pageTitle: 'Déclaration décès',
                  pageDescription: 'List des déclarations', 
              nom: null,
              libelle: null,
              description: null,
              model: null
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE'
          })
      },
      computed: {
          ...mapGetters({
              declarations: 'identification/declarationDeces',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veto: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major'
          }),
          sortedDeclarations(){
              return [...this.declarations].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Motif', name: 'motif', type: COMPONENT_TYPE, component: Motif},
                  {label: 'Déclarer par', name: 'createby', type: COMPONENT_TYPE, component: CreateBy},
                  {label: 'Date décès', name: 'dateDeces', type: DATE_TYPE},
                  {label: 'Date', name: 'createdAt', type: DATE_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions, style: {width: "15%"}}
              ]
          },
          canAdd(){
            return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto || this.is_assistant_veto_major
          }
      }
  }
  </script>